<template>
  <div>
    <div class="banner"></div>
    <div class="contBox1">
      <div class="leftImg"></div>
      <div class="rightText">
        <h3>{{ contBox1H }}</h3>
        <p v-for="item in contBox1" :key="item.value">{{ item.value }}</p>
      </div>
    </div>
    <div class="contBox2">
      <h2>主要功能</h2>
      <div>
        <div v-for="item in contBox2" :key="item.h3Txet">
          <!-- <img src="" alt="" /> -->
          <div></div>
          <h3>{{ item.h3Text }}</h3>
          <p>{{ item.pText }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contBox1H: '掌邻帮帮',
      contBox1: [
        {
          value:
            'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias.'
        },
        {
          value:
            'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias.'
        },
        {
          value:
            'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias.'
        }
      ],
      contBox2: [
        {
          h3Text: 'Choose the right property!',
          pText: 'Economy',
          url: ''
        },
        { h3Text: 'Best environment for rental', pText: 'Lifestyle', url: '' },
        {
          h3Text: 'Boys Hostel Apartment',
          pText: 'Property',
          url: ''
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.banner {
  width: 100%;
  height: 711px;
  background: url('../../../public/img/researchBGCImg.png') no-repeat;
}
.contBox1 {
  width: 1200px;
  height: 450px;
  margin: auto;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  > .leftImg {
    width: 574px;
    height: 450px;
    background: #e0e2e6;
    border-radius: 20px;
  }
  > .rightText {
    width: 588px;
    text-align: start;
    > h3 {
      height: 68px;
      font-weight: 700;
      font-size: 46px;
      color: #484848;
      line-height: 56px;
      padding: 30px 0px;
    }
    > p {
      height: 100px;
      font-weight: 400;
      font-size: 16px;
      color: #9a9a9a;
      line-height: 26px;
      margin-bottom: 10px;
    }
  }
}
.contBox2 {
  width: 1200px;
  padding: 110px 0px 100px;
  margin: auto;
  > h2 {
    font-weight: 700;
    font-size: 46px;
    color: #484848;
    margin: 12px 0px 60px;
  }
  > div {
    display: flex;
    justify-content: space-between;
    > div {
      box-sizing: border-box;
      overflow: hidden;
      text-align: start;
      > div {
        width: 382px;
        height: 340px;
        background: #e0e2e6;
        border-radius: 15px;
      }
      > h3 {
        font-weight: 700;
        font-size: 18px;
        color: #484848;
        margin: 30px 0px 10px;
      }
      > p {
        font-weight: 500;
        font-size: 14px;
        color: #9a9a9a;
      }
    }
  }
}
</style>
